import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import axios from "./../services/axios";
import { webStorage } from "./webStorage";
import { useExperimentStore } from "../stores/experimentStore";

// storageKey
const storeKey = "gclid";

function getParam(p: string) {
    const match = RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search);
    if (match && match.length >= 2) {
        return decodeURIComponent(match[1].replace(/\+/g, " "));
    }
}

/**
 * https://dev.fingerprintjs.com/docs/js-agent
 */
export const computeFingerprint = async (onlyCached = false) => {
    let fp: string = null;
    try {
        fp = await webStorage.getItem("fp");
    } catch (error) {
        console.error(error);
    }

    if (fp) {
        return { fp, confidence_score: 0.5 };
    }

    if (onlyCached) {
        return null;
    }

    try {
        const agent = await getFPjsAgentPromise();
        const result = await agent.get();
        const visitorId = result.visitorId;

        await webStorage.setItem("fp", visitorId).catch(console.error);
        return {
            fp: visitorId,
            confidence_score: !!result.confidence ? result.confidence.score : null,
        };
    } catch (error) {
        //rare event
        console.error("sendFPToBack", error);
    }
};

export const forceRecomputeFingerprint = async () => {
    await webStorage.removeItem("fp").catch(() => {});
    try {
        const fpData = await computeFingerprint();
        await axios.post(`/buurn/public/visitor/fp-check`, fpData, {
            withCredentials: true,
        });
    } catch (error) {
        console.warn("forceRecomputeFingerprint", error);
    }
};

export const ensureFingerprintAvailable = async () => {
    try {
        const fpData = await computeFingerprint();
        await axios.post(`/buurn/public/visitor/fp-check`, fpData, {
            withCredentials: true,
        });

        const experimentData = await axios.get("/buurn/public/visitor/fingerprint-tests", { withCredentials: true });
        if (experimentData && !!experimentData.data) {
            useExperimentStore.setState(experimentData.data);
        }

        return fpData;
    } catch (error) {
        console.error("forceRecomputeFingerprint", error);
    }
};

let fpjsAgentInstance = null;

export const getFPjsAgentPromise = async () => {
    if (!fpjsAgentInstance) {
        fpjsAgentInstance = await FingerprintJS.load({
            token: "FgNuLQKSXY",
            region: "eu",
            endpoint: ["https://users.luckycrush.live", FingerprintJS.defaultEndpoint],
            scriptUrlPattern: ["https://users.luckycrush.live/web/v<version>/<apiKey>/loader_v<loaderVersion>.js", FingerprintJS.defaultScriptUrlPattern],
        });
    }
    return fpjsAgentInstance;
};

// We send it to backend and save it to the fingerprint
const updateGclid = async (gclid: string) => {
    if (!gclid) {
        return;
    }
    try {
        await axios.put(`/buurn/public/visitor/gclid`, { gclid }, { withCredentials: true });
    } catch (error) {
        console.error("updateGclid", error);
    }
};

async function setGclidInStorage(gclid: string) {
    if (gclid) {
        // 90 day expiry in milliseconds
        const expiryDate = new Date().getTime() + 90 * 24 * 60 * 60 * 1000;
        const record = { value: gclid, expiryDate };
        await webStorage.setItem(storeKey, JSON.stringify(record));
    }
}

async function getGclidFromStorage(): Promise<{
    expiryDate: number;
    value: string;
}> {
    try {
        const gclid: string = await webStorage.getItem(storeKey);
        return JSON.parse(gclid);
    } catch {
        return null;
    }
}

/**
 * Reads google ad query params
 */
async function addGclid() {
    // Gclid is sent in url queries from google ads link
    const gclidParam = getParam(storeKey);
    await setGclidInStorage(gclidParam);

    /*
    The gclsrc parameter indicates the source of the click ID. Currently, it can have one of the following values:

      gclsrc=ds: the click ID was generated by Search Ads 360.
     In addition, the click was either on an engine other than Google, or the click was on Google but the Google Ads account is not using auto-tagging.

      gclsrc=aw.ds: the click ID was generated by Google Ads that is using auto-tagging, and the click went through the Search Ads 360 clickserver.

      gclsrc=: the parameter is empty if the click ID was generated by Google Ads,
      but the conversion API service was not enabled in Search Ads 360 when the click occurred.

    */
    const gclsrcParam = getParam("gclsrc");
    const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1;
    if (!isGclsrcValid) {
        await webStorage.removeItem(storeKey);
        return;
    }

    const gclid = await getGclidFromStorage();

    if (gclid && new Date().getTime() < gclid.expiryDate) {
        return gclid.value;
    } else {
        await webStorage.removeItem(storeKey);
        return;
    }
}

export async function handlegclid(data: any) {
    // user fingerprint checking
    // gclid comes from google ads, or from a previous fingerprinting process
    const gclid: string = await addGclid();
    if (data.gclid) {
        await setGclidInStorage(data.gclid);
    } else {
        //rare use case
        await updateGclid(gclid);
    }
}
