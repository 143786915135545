import { create } from "zustand";

export interface PublicConfigStore {
    configPollInterval?: number;
    favEnabledGlobally?: boolean;
    favCallMinDuration?: number;

    dcEnabledGlobally?: boolean;
    dcEnabled?: string[];
    dcMinTimeBalance?: number;
    dcBlockDuration?: number;
    dcRingTimeout?: number;
    dcIgnoredCallsIdle?: number;
    viewOnlyPossible?: boolean;
    imageModerationIntervalMales?: number;
    imageModerationIntervalFemales?: number;
    imageModerationIntervalFreeChat?: number;
    imageModerationIntervalPaidChat?: number;
    premiumBoosterEnabled?: boolean;
    premiumBoosterDuration?: number;
    introRecordingsEnabled?: boolean;
    callHeartbeatEnabled?: boolean;
    pointFreezeWatchdogTrigger?: number;
    pointFreezeWatchdogMaxRetry?: number;
    sepaOwnershipProofRequired?: boolean;
    customerCrispChatEnabled?: boolean;
    catalogSettingEnabled?: boolean;
    askForAvatarsEnabled?: boolean;
    preventFavoritesMatchEnabled?: boolean;
    welcomeFrameInterval?: number;
    toyIntegrationEnabled?: boolean;
    recommendedModelsEnabled?: boolean;

    premiumBoosterTester?: string[] | null;

    timeDiff?: number;

    setState: (newState) => void;
}

export const usePublicConfigStore = create<PublicConfigStore>((set) => ({
    configPollInterval: 10 * 60 * 1000,
    favEnabledGlobally: false,
    favCallMinDuration: 1 * 60,
    dcEnabledGlobally: false,
    dcEnabled: [],
    dcMinTimeBalance: 10 * 60,
    dcBlockDuration: 1 * 60 * 60,
    dcRingTimeout: 31,
    dcIgnoredCallsIdle: null,
    viewOnlyPossible: false,
    imageModerationIntervalMales: null,
    imageModerationIntervalFemales: null,
    imageModerationIntervalFreeChat: null,
    imageModerationIntervalPaidChat: null,
    premiumBoosterEnabled: false,
    premiumBoosterDuration: 0,
    introRecordingsEnabled: false,
    callHeartbeatEnabled: false,
    pointFreezeWatchdogTrigger: null,
    pointFreezeWatchdogMaxRetry: null,
    sepaOwnershipProofRequired: false,
    customerCrispChatEnabled: false,
    catalogSettingEnabled: false,
    premiumBoosterTester: [],
    askForAvatarsEnabled: false,
    preventFavoritesMatchEnabled: false,
    welcomeFrameInterval: null,
    toyIntegrationEnabled: false,
    recommendedModelsEnabled: false,

    timeDiff: null,

    setState: (newState) => {
        set({ ...newState });
    },
}));
