import { eventLogger } from "../services/api/eventLogger";

// sends analytics and conversions to tag manager
export const gtag = (...args: any) => {
    try {
        window["gtag"](...args);
    } catch {
        // try few ms later
        setTimeout(() => {
            try {
                window["gtag"](...args);
            } catch (error) {
                console.error("gtag", error);
            }
        }, 500);
    }

    eventLogger({
        event: "client-event",
        action: "gtag",
        args,
    }).catch(console.error);
};

export const gtagSilent = (...args: any) => {
    try {
        window["gtag"](...args);
    } catch {
        // try few ms later
        setTimeout(() => {
            try {
                window["gtag"](...args);
            } catch (error) {
                console.error("gtag", error);
            }
        }, 500);
    }
};
